import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, TwitterAuthProvider, OAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAVsFKxZ_joJgprTBP6KR9fSpcUyEjinnY",
  authDomain: "diamundialosteoporosis.firebaseapp.com",
  projectId: "diamundialosteoporosis",
  storageBucket: "diamundialosteoporosis.appspot.com",
  messagingSenderId: "40622888116",
  appId: "1:40622888116:web:b5ca2e2f1724d26946f3e1",
  measurementId: "G-3YLLTG2DLG"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app); 
const googleProvider = new GoogleAuthProvider();
const twitterProvider = new TwitterAuthProvider();
const linkedinProvider = new OAuthProvider('linkedin.com');
const microsoftProvider = new OAuthProvider('microsoft.com');
const db = getFirestore(app); 

export { auth, googleProvider, twitterProvider, linkedinProvider, microsoftProvider, db, storage };